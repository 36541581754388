const config = {
  isProduction: true,
  api_url: 'https://api.redbini.com',
  socket_url: 'https://chatsocket.redbini.com',
  wss_url_view: 'wss://chatstream.redbini.com',
  wss_url: 'wss://chatstream.redbini.com',
  site_url: 'https://redbini.com',
  googleAnalyticsKey: 'UA-99609233-1',
};

module.exports = config;
